/*eslint-disable */
export const profanityAddedWords = [
    'b!+ch',
    'blowjob',
    'clit',
    'arschloch',
    'shit',
    'ass',
    'b!tch',
    'b17ch',
    'b1tch',
    'bastard',
    'bi+ch',
    'boiolas',
    'buceta',
    'c0ck',
    'cawk',
    'chink',
    'cipa',
    'clits',
    'cock',
    'cum',
    'cunt',
    'dildo',
    'dirsa',
    'ejakulate',
    'fatass',
    'fcuk',
    'fuk',
    'fux0r',
    'hoer',
    'hore',
    'jism',
    'kawk',
    'l3itch',
    'l3i+ch',
    'lesbian',
    'masturbate',
    'masterbat*',
    'masterbat3',
    'motherfucker',
    's.o.b.',
    'mofo',
    'nazi',
    'nigga',
    'nigger',
    'nutsack',
    'phuck',
    'pimpis',
    'pusse',
    'pussy',
    'scrotum',
    'sh!t',
    'shemale',
    'shi+',
    'sh!+',
    'slut',
    'smut',
    'teets',
    'tits',
    'boobs',
    'b00bs',
    'teez',
    'testical',
    'testicle',
    'titt',
    'w00se',
    'jackoff',
    'wank',
    'whoar',
    'whore',
    '*damn',
    '*dyke',
    '*fuck*',
    '*shit*',
    '@$$',
    'amcik',
    'andskota',
    'arse*',
    'assrammer',
    'ayir',
    'bi7ch',
    'bitch*',
    'bollock*',
    'breasts',
    'butt-pirate',
    'cabron',
    'cazzo',
    'chraa',
    'chuj',
    'Cock*',
    'cunt*',
    'd4mn',
    'daygo',
    'dego',
    'dick*',
    'dike*',
    'dupa',
    'dziwka',
    'ejackulate',
    'Ekrem*',
    'Ekto',
    'enculer',
    'faen',
    'fag*',
    'fanculo',
    'fanny',
    'feces',
    'feg',
    'Felcher',
    'ficken',
    'fitt*',
    'Flikker',
    'foreskin',
    'Fotze',
    'Fu(*',
    'fuk*',
    'futkretzn',
    'gook',
    'guiena',
    'h0r',
    'h4x0r',
    'helvete',
    'hoer*',
    'honkey',
    'Huevon',
    'hui',
    'injun',
    'jizz',
    'kanker*',
    'kike',
    'klootzak',
    'kraut',
    'knulle',
    'kuk',
    'kuksuger',
    'Kurac',
    'kurwa',
    'kusi*',
    'kyrpa*',
    'lesbo',
    'mamhoon',
    'masturbat*',
    'merd*',
    'mibun',
    'monkleigh',
    'mouliewop',
    'muie',
    'mulkku',
    'muschi',
    'nazis',
    'nepesaurio',
    'nigger*',
    'orospu',
    'paska*',
    'perse',
    'picka',
    'pierdol*',
    'pillu*',
    'pimmel',
    'piss*',
    'pizda',
    'poontsee',
    'poop',
    'porn',
    'p0rn',
    'pr0n',
    'preteen',
    'pula',
    'pule',
    'puta',
    'puto',
    'qahbeh',
    'queef*',
    'rautenberg',
    'schaffer',
    'scheiss*',
    'schlampe',
    'schmuck',
    'screw',
    'sh!t*',
    'sharmuta',
    'sharmute',
    'shipal',
    'shiz',
    'skribz',
    'skurwysyn',
    'sphencter',
    'spic',
    'spierdalaj',
    'splooge',
    'suka',
    'b00b*',
    'testicle*',
    'titt*',
    'twat',
    'vittu',
    'wank*',
    'wetback*',
    'wichser',
    'wop*',
    'yed',
    'zabourah',
    'Anus',
    'Baba-ovo',
    'Babaovo',
    'Babaca',
    'Bacura',
    'Bagos',
    'Baitola',
    'Bebum',
    'Besta',
    'Bicha',
    'Bisca',
    'Bixa',
    'Boazuda',
    'Boceta',
    'Boco',
    'Boiola',
    'Bolagato',
    'Boquete',
    'Bolcat',
    'Bosseta',
    'Bosta',
    'Bostana',
    'Brecha',
    'Brexa',
    'Brioco',
    'Bronha',
    'Buca',
    'Buceta',
    'Bunda',
    'Bunduda',
    'Burra',
    'Burro',
    'Busseta',
    'Cachorra',
    'Cachorro',
    'Cadela',
    'Caga',
    'Cagado',
    'Cagao',
    'Cagão',
    'Cagona',
    'Canalha',
    'Caralho',
    'Casseta',
    'Cassete',
    'Checheca',
    'Chereca',
    'Chibumba',
    'Chibumbo',
    'Chifruda',
    'Chifrudo',
    'Chota',
    'Chochota',
    'Chupada',
    'Chupado',
    'Clitoris',
    'Cocaina',
    'Coco',
    'Corna',
    'Corno',
    'Cornuda',
    'Cornudo',
    'Corrupta',
    'Corrupto',
    'Cretina',
    'Cretino',
    'Cruz-credo',
    'Cu',
    'Culhao',
    'Curalho',
    'Cuzao',
    'Cuzuda',
    'Cuzudo',
    'Debil',
    'Debiloide',
    'Defunto',
    'Demonio',
    'Difunto',
    'Doida',
    'Doido',
    'Egua',
    'Escrota',
    'Escroto',
    'Esporrada',
    'Esporrado',
    'Esporro',
    'Estupida',
    'Estupidez',
    'Estupido',
    'Fedida',
    'Fedido',
    'Fedor',
    'Fedorenta',
    'Feia',
    'Feio',
    'Feiosa',
    'Feioso',
    'Feioza',
    'Feiozo',
    'Felacao',
    'Fenda',
    'Foda',
    'Fodao',
    'Fode',
    'FodidaFodido',
    'Fornica',
    'Fudendo',
    'Fudecao',
    'Fudida',
    'Fudido',
    'Furada',
    'Furado',
    'Fur\u00e3o',
    'Furnica',
    'Furnicar',
    'Furo',
    'Furona',
    'Gaiata',
    'Gaiato',
    'Gay',
    'Gonorrea',
    'Gonorreia',
    'Gosma',
    'Gosmenta',
    'Gosmento',
    'Grelinho',
    'Grelo',
    'Homo-sexual',
    'Homossexual',
    'Homossexual',
    'Idiota',
    'Idiotice',
    'Imbecil',
    'Iscrota',
    'Iscroto',
    'Japa',
    'Lesbian',
    'L3sbi4n',
    'Ladra',
    'Ladrao',
    'Ladroeira',
    'Ladrona',
    'Lalau',
    'Leprosa',
    'Leproso',
    'L\u00e9sbica',
    'Macaca',
    'Macaco',
    'Machona',
    'Machorra',
    'Manguaca',
    'Mangua\u00a6a',
    'Masturba',
    'Meleca',
    'Merda',
    'Mija',
    'Mijada',
    'Mijado',
    'Mijo',
    'Mocrea',
    'Mocreia',
    'Moleca',
    'Moleque',
    'Mondronga',
    'Mondrongo',
    'Naba',
    'Nadega',
    'Nojeira',
    'Nojenta',
    'Nojento',
    'Nojo',
    'Olhota',
    'Otaria',
    'Ot-ria',
    'Otario',
    'Ot-rio',
    'Paca',
    'Paspalha',
    'Paspalhao',
    'Paspalho',
    'Pau',
    'Peia',
    'Peido',
    'Pemba',
    'P\u00eanis',
    'Pentelha',
    'Pentelho',
    'Perereca',
    'Peru',
    'Pica',
    'Picao',
    'Pilantra',
    'Piranha',
    'Piroca',
    'Piroco',
    'Piru',
    'Porra',
    'Prega',
    'Prostibulo',
    'Prost-bulo',
    'Prostituta',
    'Prostituto',
    'Punheta',
    'Punhetao',
    'Pus',
    'Pustula',
    'Puta',
    'Puto',
    'Puxa-saco',
    'Puxasaco',
    'Rabao',
    'Rabo',
    'Rabuda',
    'Rabudao',
    'Rabudo',
    'Rabudona',
    'Racha',
    'Rachada',
    'Rachadao',
    'Rachadinha',
    'Rachadinho',
    'Rachado',
    'Ramela',
    'Remela',
    'Retardada',
    'Retardado',
    'Rid\u00edcula',
    'Rola',
    'Rolinha',
    'Rosca',
    'Sacana',
    'Safada',
    'Safado',
    'Sapatao',
    'Sifilis',
    'Siririca',
    'Tarada',
    'Tarado',
    'Testuda',
    'Tezao',
    'Tezuda',
    'Tezudo',
    'Trocha',
    'Trolha',
    'Troucha',
    'Trouxa',
    'Troxa',
    'Vaca',
    'Vagabunda',
    'Vagabundo',
    'Vagina',
    'Veada',
    'Veadao',
    'Veado',
    'Viada',
    'V14d0',
    'V\u00edado',
    'Viadao',
    'Xavasca',
    'Xerereca',
    'Xexeca',
    'Xibiu',
    'Xibumba',
    'Xota',
    'Xochota',
    'Xoxota',
    'Xana',
    'Xaninha',
    'abo',
    'Africoon',
    'Africoon-Americoon',
    'Africoonian',
    'Africunt',
    'ah tiong',
    'albo',
    'Ali Baba',
    'Americoon',
    'Americunt',
    'Amerikkkunt',
    'apefirmative action',
    'Apefrica',
    'Apefrican',
    'Apelanta',
    'apple',
    'Aunt Jane',
    'Aunt Jemima',
    'Aunt Thomasina',
    'bagel',
    'baguette',
    'banana',
    'Bangla',
    'bean-eater',
    'beanbreath',
    'beaner',
    'bix nood',
    'Black Dutch',
    'black Irish',
    'blacky',
    'Boche',
    'bohunk',
    'boogie',
    'boong',
    'boonga',
    'bootlip',
    'bootlipped',
    'booty scratcher',
    'brillo pad',
    'brownie',
    'buckwheat',
    'burn coal',
    'burrhead',
    'bushy',
    'CAC',
    'Cajun',
    'camel jockey',
    'camelfucker',
    'Cape Dutch',
    'Casper',
    'cheese monkey',
    'cheesehead',
    'Chen',
    'chickenlips',
    'Chimpcongo',
    'chimpout',
    'China bug',
    'Chinese farmer',
    'ching chong',
    'Chink',
    'chinky chonk',
    'chocolate face',
    'chopstick',
    'Christ-killer',
    'chug',
    'chuhra',
    'clog wog',
    'coal burner',
    'cockroach',
    'coconut',
    'Conch',
    'congresscoon',
    'coon',
    'coonass',
    'coonfuse',
    'coonmunity',
    'Coontown',
    'cotton picker',
    'cracka',
    'cracker',
    'cracker-ass',
    'crackerass',
    'cracklet',
    'crapaud',
    'criminigger',
    'Crocodile Humper',
    'crow',
    'crunchie',
    'cullud pusson',
    'cumskin',
    'cumskinned',
    'curry nigger',
    'curry-muncher',
    'currycel',
    'Curryland',
    'currywhore',
    'DAFN',
    'dago',
    'dago bomb',
    'dago dazzler',
    'darkey',
    'Deutschbag',
    'diaperhead',
    'diego',
    'dindu nuffin',
    'dogeater',
    'dothead',
    'dune coon',
    'durka durka',
    'Dutch',
    'Dutchman',
    'Dutchwoman',
    'egg',
    'eight-ball',
    'Englishman',
    'Eyetie',
    'farang',
    'farm nigger',
    'featherwood',
    'fishhead',
    'Flip',
    'Fritz',
    'frog',
    'frogese',
    'froggy',
    'froglet',
    'Frogspeak',
    'fuzzy-headed',
    'fuzzy-minded',
    'G',
    'gator bait',
    'get someones Irish up',
    'ginzo',
    'gippo',
    'goffel',
    'golly',
    'goo-goo',
    'googoo',
    'gook',
    'gook wagon',
    'gookland',
    'goombah',
    'grasseater',
    'greaseball',
    'greaser',
    'gringo',
    'groid',
    'Guat',
    'gugu',
    'guidette',
    'guido',
    'Guinea',
    'guinea',
    'gussuk',
    'gutter ape',
    'gweilo',
    'gweipo',
    'gyppo',
    'gyppy',
    'gypsyism',
    'hairyback',
    'hajji',
    'half-breed',
    'heeb',
    'heebish',
    'hick',
    'hillbilly',
    'honklet',
    'honky',
    'honorable',
    'hoogie',
    'hoojah',
    'house nigger',
    'hout',
    'hunky',
    'Hymie',
    'ice monkey',
    'Indian nigger',
    'injun',
    'jaboon',
    'Jacky',
    'Jap',
    'jap',
    'japie',
    'Japland',
    'Jerry',
    'jerry',
    'Jewfucker',
    'Jewish nose',
    'Jewtard',
    'jig',
    'jigaboo',
    'Jim Crow',
    'Johnny Crapaud',
    'jook-sing',
    'Judeo-Bolshevik',
    'Judeo-Bolshevist',
    'jungle bunny',
    'jungle fever',
    'kaffir',
    'kangaroo fucker',
    'katsap',
    'kebab',
    'Keling',
    'khokhol',
    'kike',
    'kikey',
    'kimchi',
    'kiwi fucker',
    'Knackerville',
    'kneegrow',
    'koala fucker',
    'Kraut',
    'Latrino',
    'Leb',
    'Lebo',
    'limey',
    'little brown fucking machine',
    'macaca',
    'malaun',
    'mangia-cake',
    'Mangkali',
    'Mat',
    'mayo',
    'mayonnaise face',
    'mean white',
    'Mexcrement',
    'Mexicoon',
    'Mexicunt',
    'Mexishit',
    'mick',
    'moke',
    'monkey',
    'mooley',
    'moolie',
    'moolinyan',
    'moon cricket',
    'mud',
    'mud shark',
    'mud sharking',
    'munt',
    'nappyhead',
    'Nazi',
    'negress',
    'negrette',
    'Nep',
    'nicker',
    'nig',
    'nig-nog',
    'nigcel',
    'nigfant',
    'nigga',
    'Niggatown',
    'nigger',
    'nigger chaser',
    'nigger in the fence',
    'nigger in the woodpile',
    'nigger killer',
    'nigger knocker',
    'nigger lottery',
    'nigger lover',
    'nigger nose',
    'nigger rich',
    'nigger shooter',
    'nigger stick',
    'nigger university',
    'nigger-brown',
    'niggeration',
    'niggerbabble',
    'niggerball',
    'niggerbitch',
    'niggerboy',
    'niggercide',
    'niggerdick',
    'niggerese',
    'niggeresque',
    'niggerette',
    'niggerfaggot',
    'niggerfied',
    'niggerfuck',
    'niggerfucker',
    'niggerfuxate',
    'niggerfuxated',
    'niggerfuxation',
    'niggergram',
    'Niggerian',
    'niggerish',
    'niggerishly',
    'niggerishness',
    'niggerism',
    'niggerization',
    'niggerize',
    'Niggerland',
    'niggerless',
    'niggerlike',
    'niggerling',
    'niggerlips',
    'niggerly',
    'niggerman',
    'niggerology',
    'niggerous',
    'niggership',
    'niggerskin',
    'niggerspeak',
    'Niggertown',
    'niggertry',
    'Niggerville',
    'niggerwhine',
    'niggery',
    'niggress',
    'niggy',
    'niglet',
    'niglette',
    'nigra',
    'nigro',
    'nigtard',
    'nilla',
    'Nip',
    'nog',
    'nogger',
    'noggie',
    'noodlewhore',
    'Nork',
    'Norte',
    'Norteño',
    'oaxaquita',
    'ofay',
    'oil trash',
    'oiler',
    'Oreo',
    'pajeet',
    'Paki',
    'paleass',
    'Palesimian',
    'Palestinkian',
    'pasty',
    'pavement ape',
    'pea soup',
    'peckerwood',
    'pekkie',
    'Pepsi',
    'pepsi',
    'petrol sniffer',
    'pickaninny',
    'pinky',
    'piss drinker',
    'pizza nigger',
    'pizzahead',
    'plantation nigger',
    'Plastic Paddy',
    'pollywog',
    'pommy',
    'poopskin',
    'poor white trash',
    'porch monkey',
    'Porki',
    'Portagee',
    'potato chaser',
    'potato nigger',
    'prairie nigger',
    'promdi',
    'pumpkin head',
    'puncturewala',
    'raghead',
    'rape ape',
    'Rape',
    'Raping',
    'Rapist',
    'red man',
    'red nigger',
    'redneck',
    'redneckitis',
    'redskin',
    'reffo',
    'reparations collector',
    'reptilian',
    'rice',
    'rice bag',
    'rice burner',
    'rice chaser',
    'rice nigger',
    'rice rocket',
    'rice-eater',
    'ricecel',
    'ricer',
    'ricewhore',
    'rockfish',
    'rooinek',
    'roundeye',
    'rug pilot',
    'rug rider',
    'Rusky',
    'Russki',
    'sakai',
    'Sambo',
    'sand nigger',
    'sandnigga',
    'sauerkraut',
    'schvartze',
    'schwartza',
    'scrap',
    'section ape',
    'Serbo-Bolshevik',
    'Serbo-Communist',
    'sheboon',
    'sheeny',
    'shegetz',
    'sherilla',
    'shitnigger',
    'shitskin',
    'shitskinned',
    'shoneen',
    'shvartze',
    'shvartzer',
    'shylock',
    'Skinnie',
    'skinnie',
    'slant',
    'slanteye',
    'slope',
    'smoked Irish',
    'smoked Irishman',
    'snakehead',
    'snow bunny',
    'snow-white',
    'snuff-and-butter',
    'spade',
    'spaghetti bender',
    'spearchucker',
    'spic',
    'Spicanese',
    'spicish',
    'spiclet',
    'spicspeak',
    'spigger',
    'spook',
    'squaw',
    '-stan',
    'street ape',
    'sulla',
    'suspook',
    'swamp nigger',
    'sweat like a nigger',
    'sweat like a nigger on election day',
    'swigger',
    'taco nigger',
    'taco-bender',
    'tar baby',
    'teenaper',
    'teenigger',
    'timber nigger',
    'TNB',
    'Tojo',
    'toll paid',
    'tootsoon',
    'towelhead',
    'towelheaded',
    'trailer nigger',
    'trailer park trash',
    'trailer trash',
    'tree nigger',
    'turdler',
    'Twinkie',
    'Uncle Tom',
    'Uncle Tomahawk',
    'vanilla wafer',
    'wapanese',
    'wetback',
    'white boy',
    'white bread',
    'white devil',
    'white negro',
    'white nigger',
    'white trash',
    'whiteass',
    'whiteboy',
    'whiteskin',
    'whitey',
    'wigger',
    'wog',
    'wogball',
    'woggish',
    'woggy',
    'Wogland',
    'wogspeak',
    'wood',
    'woolly-head',
    'woolly-headed',
    'wop',
    'woppish',
    'wopspeak',
    'yard ape',
    'yarpie',
    'yellow',
    'yellow cab',
    'yellow man',
    'yellow nigger',
    'YT',
    'zebra',
    'ziggaboo',
    'ZioJew',
    'zipperhead',
    'Zot',
    'Baby mama',
    'Battle-axe (woman)',
    'Becky (slang)',
    'Bimbo',
    'Bitch (slang)',
    'Boseulachi',
    'Cat lady',
    'Chinese dama',
    'Cock tease',
    'Cougar (slang)',
    'Crone',
    'Cunt',
    'Doenjang girl',
    'Dog poop girl',
    'Dyke (slang)',
    'Essex girl',
    'Fag hag',
    'Female hysteria',
    'Feminazi',
    'Floozie',
    'Gold digger',
    'Gold Miss',
    'Hag',
    'Harpy',
    'Karen (slang)',
    'Kyōiku mama',
    'Lesbian until graduation',
    'Lesbo',
    'Loosu ponnu',
    'Miss Ann',
    'Moll (slang)',
    'MRS Degree',
    'Nakusha',
    'Nowhere girls',
    'Queen bee (sociology)',
    'Radical chic',
    'Sarong party girl',
    'Shemale',
    'Shrew (stock character)',
    'Skintern',
    'Slut',
    'Spinster',
    'Squaw',
    'Suzy Homemaker',
    'Termagant',
    'Trophy wife',
    'Twat',
    'Virago',
    'WAGs',
    'Welfare queen',
    'Whore',
    'Sissy',
    'Girlie man',
    'Pansy',
    'Janus',
    'Sodomite',
    'Nancy',
    'Poof',
    'Poofter',
    'Fairy',
    'Brownie',
    'Pillow-biter',
    'Bugger',
    'Cola',
    'Fudgepacker',
    'Nelly',
    'Fag hag',
    'Rug muncher',
    'Marimacho',
    'Homo',
    'Homintern',
    'Invert',
    'Bent',
    'Fjolla',
    'Machobög',
    'Viado',
    'Shirtlifter',
    'Bardash',
    'Bakla',
    'Batiman',
    'Flamer',
    'Fruit',
    'Moffie',
    'Ponce',
    'Tapette',
    'Steamer',
    'Nola',
    'Flit',
    'Daffodil',
    'Jocker',
    'Quean',
    'Greek',
    'Tonk',
    'Lizzie',
    'Wonk',
    'Dorian',
    'Poove',
    'Sod',
    'Fairy',
    'Ogay',
    'Batty boy',
    'nancy boy',
    'Woofter',
    'Twinkie',
    'Swish',
    'Nelly/nellie',
    'Pédale',
    'Twink',
    'Dyke',
    'Bollera',
    'Tortillera',
    'Lesbo',
    'Flata',
    'Bofe',
    'Caminhoneira',
    'Gouine',
    'Goudou',
    'AC/DC',
    'Switch hitter',
    'Lug',
    'Fauxbians',
    'Tranny',
    'Shim',
    'HeShe',
    'Transtrender',
    'Troon',
    'Cuntboy',
    'Hefemale',
    'Shemale',
    'Dickgirl',
    'Ladyboy',
    'Chick with dick',
    'Mahu',
    'Shim',
    'Trap',
    'Trannie',
    'Hermie',
    'Izak',
    'Hybrid',
    'Transa',
    'Transvestite',
    'Quean',
    'Pondan',
    'Tranny',
    'She-Man',
    'Okama',
    'Banci',
    'Bencong',
    'Hommasse',
    'Hermaphroditi',
    '4r5e',
    '5h1t',
    '5hit',
    'a55',
    'anal',
    'anus',
    'ar5e',
    'arrse',
    'arse',
    'ass',
    'ass-fucker',
    'asses',
    'assfucker',
    'assfukka',
    'asshole',
    'assholes',
    'asswhole',
    'a_s_s',
    'b!tch',
    'b00bs',
    'b17ch',
    'b1tch',
    'ballbag',
    'balls',
    'ballsack',
    'bastard',
    'beastial',
    'beastiality',
    'bellend',
    'bestial',
    'bestiality',
    'bi+ch',
    'biatch',
    'bitch',
    'bitcher',
    'bitchers',
    'bitches',
    'bitchin',
    'bitching',
    'bloody',
    'blow job',
    'blowjob',
    'blowjobs',
    'boiolas',
    'bollock',
    'bollok',
    'boner',
    'boob',
    'boobs',
    'booobs',
    'boooobs',
    'booooobs',
    'booooooobs',
    'breasts',
    'buceta',
    'bugger',
    'bum',
    'bunny fucker',
    'butt',
    'butthole',
    'buttmuch',
    'buttplug',
    'c0ck',
    'c0cksucker',
    'carpet muncher',
    'cawk',
    'chink',
    'cipa',
    'cl1t',
    'clit',
    'clitoris',
    'clits',
    'cnut',
    'cock',
    'cock-sucker',
    'cockface',
    'cockhead',
    'cockmunch',
    'cockmuncher',
    'cocks',
    'cocksuck',
    'cocksucked',
    'cocksucker',
    'cocksucking',
    'cocksucks',
    'cocksuka',
    'cocksukka',
    'cok',
    'cokmuncher',
    'coksucka',
    'coon',
    'cox',
    'crap',
    'cum',
    'cummer',
    'cumming',
    'cums',
    'cumshot',
    'cunilingus',
    'cunillingus',
    'cunnilingus',
    'cunt',
    'cuntlick',
    'cuntlicker',
    'cuntlicking',
    'cunts',
    'cyalis',
    'cyberfuc',
    'cyberfuck',
    'cyberfucked',
    'cyberfucker',
    'cyberfuckers',
    'cyberfucking',
    'd1ck',
    'damn',
    'dick',
    'dickhead',
    'dildo',
    'dildos',
    'dink',
    'dinks',
    'dirsa',
    'dlck',
    'dog-fucker',
    'doggin',
    'dogging',
    'donkeyribber',
    'doosh',
    'duche',
    'dyke',
    'ejaculate',
    'ejaculated',
    'ejaculates',
    'ejaculating',
    'ejaculatings',
    'ejaculation',
    'ejakulate',
    'f u c k',
    'f u c k e r',
    'f4nny',
    'fag',
    'fagging',
    'faggitt',
    'faggot',
    'faggs',
    'fagot',
    'fagots',
    'fags',
    'fanny',
    'fannyflaps',
    'fannyfucker',
    'fanyy',
    'fatass',
    'fcuk',
    'fcuker',
    'fcuking',
    'feck',
    'fecker',
    'felching',
    'fellate',
    'fellatio',
    'fingerfuck',
    'fingerfucked',
    'fingerfucker',
    'fingerfuckers',
    'fingerfucking',
    'fingerfucks',
    'fistfuck',
    'fistfucked',
    'fistfucker',
    'fistfuckers',
    'fistfucking',
    'fistfuckings',
    'fistfucks',
    'flange',
    'fook',
    'fooker',
    'fuck',
    'fucka',
    'fucked',
    'fucker',
    'fuckers',
    'fuckhead',
    'fuckheads',
    'fuckin',
    'fucking',
    'fuckings',
    'fuckingshitmotherfucker',
    'fuckme',
    'fucks',
    'fuckwhit',
    'fuckwit',
    'fudge packer',
    'fudgepacker',
    'fuk',
    'fuker',
    'fukker',
    'fukkin',
    'fuks',
    'fukwhit',
    'fukwit',
    'fux',
    'fux0r',
    'f_u_c_k',
    'gangbang',
    'gangbanged',
    'gangbangs',
    'gaylord',
    'gaysex',
    'goatse',
    'god-dam',
    'god-damned',
    'goddamn',
    'goddamned',
    'hardcoresex',
    'hell',
    'heshe',
    'hoar',
    'hoare',
    'hoer',
    'homo',
    'hore',
    'horniest',
    'horny',
    'hotsex',
    'jack-off',
    'jackoff',
    'jap',
    'jerk-off',
    'jism',
    'jiz',
    'jizm',
    'jizz',
    'kawk',
    'knob',
    'knobead',
    'knobed',
    'knobend',
    'knobhead',
    'knobjocky',
    'knobjokey',
    'kock',
    'kondum',
    'kondums',
    'kum',
    'kummer',
    'kumming',
    'kums',
    'kunilingus',
    'l3i+ch',
    'l3itch',
    'labia',
    'lmfao',
    'lust',
    'lusting',
    'm0f0',
    'm0fo',
    'm45terbate',
    'ma5terb8',
    'ma5terbate',
    'masochist',
    'master-bate',
    'masterb8',
    'masterbat',
    'masterbat3',
    'masterbate',
    'masterbation',
    'masterbations',
    'masturbate',
    'mo-fo',
    'mof0',
    'mofo',
    'mothafuck',
    'mothafucka',
    'mothafuckas',
    'mothafuckaz',
    'mothafucked',
    'mothafucker',
    'mothafuckers',
    'mothafuckin',
    'mothafucking',
    'mothafuckings',
    'mothafucks',
    'mother fucker',
    'motherfuck',
    'motherfucked',
    'motherfucker',
    'motherfuckers',
    'motherfuckin',
    'motherfucking',
    'motherfuckings',
    'motherfuckka',
    'motherfucks',
    'muff',
    'mutha',
    'muthafecker',
    'muthafuckker',
    'muther',
    'mutherfucker',
    'n1gga',
    'n1gger',
    'nazi',
    'nigg3r',
    'nigg4h',
    'nigga',
    'niggah',
    'niggas',
    'niggaz',
    'nigger',
    'niggers',
    'nob',
    'nob jokey',
    'nobhead',
    'nobjocky',
    'nobjokey',
    'numbnuts',
    'nutsack',
    'orgasim',
    'orgasims',
    'orgasm',
    'orgasms',
    'p0rn',
    'pawn',
    'pecker',
    'penis',
    'penisfucker',
    'phonesex',
    'phuck',
    'phuk',
    'phuked',
    'phuking',
    'phukked',
    'phukking',
    'phuks',
    'phuq',
    'pigfucker',
    'pimpis',
    'piss',
    'pissed',
    'pisser',
    'pissers',
    'pisses',
    'pissflaps',
    'pissin',
    'pissing',
    'pissoff',
    'poop',
    'porn',
    'porno',
    'pornography',
    'pornos',
    'prick',
    'pricks',
    'pron',
    'pube',
    'pusse',
    'pussi',
    'pussies',
    'pussy',
    'pussys',
    'rectum',
    'retard',
    'rimjaw',
    'rimming',
    's hit',
    's.o.b.',
    'sadist',
    'schlong',
    'screwing',
    'scroat',
    'scrote',
    'scrotum',
    'semen',
    'sex',
    'sh!+',
    'sh!t',
    'sh1t',
    'shag',
    'shagger',
    'shaggin',
    'shagging',
    'shemale',
    'shi+',
    'shit',
    'shitdick',
    'shite',
    'shited',
    'shitey',
    'shitfuck',
    'shitfull',
    'shithead',
    'shiting',
    'shitings',
    'shits',
    'shitted',
    'shitter',
    'shitters',
    'shitting',
    'shittings',
    'shitty',
    'skank',
    'slut',
    'sluts',
    'smegma',
    'smut',
    'snatch',
    'son-of-a-bitch',
    'spac',
    'spunk',
    's_h_i_t',
    't1tt1e5',
    't1tties',
    'teets',
    'teez',
    'testical',
    'testicle',
    'tit',
    'titfuck',
    'tits',
    'titt',
    'tittie5',
    'tittiefucker',
    'titties',
    'tittyfuck',
    'tittywank',
    'titwank',
    'tosser',
    'turd',
    'tw4t',
    'twat',
    'twathead',
    'twatty',
    'twunt',
    'twunter',
    'v14gra',
    'v1gra',
    'vagina',
    'viagra',
    'vulva',
    'w00se',
    'wang',
    'wank',
    'wanker',
    'wanky',
    'whoar',
    'whore',
    'willies',
    'willy',
    'xrated',
    'xxx',
    'arsehole',
    'assbag',
    'assbandit',
    'assbanger',
    'assbite',
    'assclown',
    'asscock',
    'asscracker',
    'assface',
    'assfuck',
    'assgoblin',
    'asshat',
    'ass-hat',
    'asshead',
    'asshopper',
    'ass-jabber',
    'assjacker',
    'asslick',
    'asslicker',
    'assmonkey',
    'assmunch',
    'assmuncher',
    'assnigger',
    'asspirate',
    'ass-pirate',
    'assshit',
    'assshole',
    'asssucker',
    'asswad',
    'asswipe',
    'axwound',
    'bampot',
    'beaner',
    'bitchass',
    'bitchtits',
    'bitchy',
    'bollocks',
    'bollox',
    'brotherfucker',
    'bullshit',
    'bumblefuck',
    'butt plug',
    'buttfucka',
    'butt-pirate',
    'buttfucker',
    'camel toe',
    'carpetmuncher',
    'chesticle',
    'chinc',
    'choad',
    'chode',
    'clitface',
    'clitfuck',
    'clusterfuck',
    'cockass',
    'cockbite',
    'cockburger',
    'cockfucker',
    'cockjockey',
    'cockknoker',
    'cockmaster',
    'cockmongler',
    'cockmongruel',
    'cockmonkey',
    'cocknose',
    'cocknugget',
    'cockshit',
    'cocksmith',
    'cocksmoke',
    'cocksmoker',
    'cocksniffer',
    'cockwaffle',
    'coochie',
    'coochy',
    'cooter',
    'cracker',
    'cumbubble',
    'cumdumpster',
    'cumguzzler',
    'cumjockey',
    'cumslut',
    'cumtart',
    'cunnie',
    'cuntass',
    'cuntface',
    'cunthole',
    'cuntrag',
    'cuntslut',
    'dago',
    'deggo',
    'dickbag',
    'dickbeaters',
    'dickface',
    'dickfuck',
    'dickfucker',
    'dickhole',
    'dickjuice',
    'dickmilk ',
    'dickmonger',
    'dicks',
    'dickslap',
    'dick-sneeze',
    'dicksucker',
    'dicksucking',
    'dicktickler',
    'dickwad',
    'dickweasel',
    'dickweed',
    'dickwod',
    'dike',
    'dipshit',
    'doochbag',
    'dookie',
    'douche',
    'douchebag',
    'douche-fag',
    'douchewaffle',
    'dumass',
    'dumb ass',
    'dumbass',
    'dumbfuck',
    'dumbshit',
    'dumshit',
    'fagbag',
    'fagfucker',
    'faggit',
    'faggotcock',
    'fagtard',
    'feltch',
    'flamer',
    'fuckass',
    'fuckbag',
    'fuckboy',
    'fuckbrain',
    'fuckbutt',
    'fuckbutter',
    'fuckersucker',
    'fuckface',
    'fuckhole',
    'fucknut',
    'fucknutt',
    'fuckoff',
    'fuckstick',
    'fucktard',
    'fucktart',
    'fuckup',
    'fuckwad',
    'fuckwitt',
    'gay',
    'gayass',
    'gaybob',
    'gaydo',
    'gayfuck',
    'gayfuckist',
    'gaytard',
    'gaywad',
    'goddamnit',
    'gooch',
    'gook',
    'gringo',
    'guido',
    'handjob',
    'hard on',
    'heeb',
    'ho',
    'hoe',
    'homodumbshit',
    'honkey',
    'humping',
    'jackass',
    'jagoff',
    'jerk off',
    'jerkass',
    'jigaboo',
    'jungle bunny',
    'junglebunny',
    'kike',
    'kooch',
    'kootch',
    'kraut',
    'kunt',
    'kyke',
    'lameass',
    'lardass',
    'lesbian',
    'lesbo',
    'lezzie',
    'mcfagget',
    'mick',
    'minge',
    'muffdiver',
    'munging',
    'negro',
    'nigaboo',
    'niglet',
    'nut sack',
    'paki',
    'panooch',
    'peckerhead',
    'penisbanger',
    'penispuffer',
    'pissed off',
    'polesmoker',
    'pollock',
    'poon',
    'poonani',
    'poonany',
    'poontang',
    'porch monkey',
    'porchmonkey',
    'punanny',
    'punta',
    'pussylicking',
    'puto',
    'queef',
    'queer',
    'queerbait',
    'queerhole',
    'renob',
    'rimjob',
    'ruski',
    'sand nigger',
    'sandnigger',
    'shitass',
    'shitbag',
    'shitbagger',
    'shitbrains',
    'shitbreath',
    'shitcanned',
    'shitcunt',
    'shitface',
    'shitfaced',
    'shithole',
    'shithouse',
    'shitspitter',
    'shitstain',
    'shittiest',
    'shiz',
    'shiznit',
    'skeet',
    'skullfuck',
    'slutbag',
    'smeg',
    'spic',
    'spick',
    'splooge',
    'spook',
    'suckass',
    'tard',
    'thundercunt',
    'twatlips',
    'twats',
    'twatwaffle',
    'unclefucker',
    'vag',
    'vajayjay',
    'va-j-j',
    'vjayjay',
    'wankjob',
    'wetback',
    'whorebag',
    'whoreface',
    'wop',
    'breeder',
    'cocklump',
    'creampie',
    'doublelift',
    'dumbcunt',
    'fuck off',
    'incest',
    'jack Off',
    'poopuncher',
    'sandler',
    'cockeye',
    'crotte',
    'foah',
    'fucktwat',
    'jaggi',
    'kunja',
    'pust',
    'sanger',
    'seks',
    'slag',
    'zubb',
    '2g1c',
    '2 girls 1 cup',
    'acrotomophilia',
    'alabama hot pocket',
    'alaskan pipeline',
    'anilingus',
    'apeshit',
    'auto erotic',
    'autoerotic',
    'babeland',
    'baby batter',
    'baby juice',
    'ball gag',
    'ball gravy',
    'ball kicking',
    'ball licking',
    'ball sack',
    'ball sucking',
    'bangbros',
    'bareback',
    'barely legal',
    'barenaked',
    'bastardo',
    'bastinado',
    'bbw',
    'bdsm',
    'beaners',
    'beaver cleaver',
    'beaver lips',
    'big black',
    'big breasts',
    'big knockers',
    'big tits',
    'bimbos',
    'birdlock',
    'black cock',
    'blonde action',
    'blonde on blonde action',
    'blow your load',
    'blue waffle',
    'blumpkin',
    'bondage',
    'booty call',
    'brown showers',
    'brunette action',
    'bukkake',
    'bulldyke',
    'bullet vibe',
    'bung hole',
    'bunghole',
    'busty',
    'buttcheeks',
    'camgirl',
    'camslut',
    'camwhore',
    'chocolate rosebuds',
    'circlejerk',
    'cleveland steamer',
    'clover clamps',
    'coprolagnia',
    'coprophilia',
    'cornhole',
    'coons',
    'darkie',
    'date rape',
    'daterape',
    'deep throat',
    'deepthroat',
    'dendrophilia',
    'dingleberry',
    'dingleberries',
    'dirty pillows',
    'dirty sanchez',
    'doggie style',
    'doggiestyle',
    'doggy style',
    'doggystyle',
    'dog style',
    'dolcett',
    'domination',
    'dominatrix',
    'dommes',
    'donkey punch',
    'double dong',
    'double penetration',
    'dp action',
    'dry hump',
    'dvda',
    'eat my ass',
    'ecchi',
    'erotic',
    'erotism',
    'escort',
    'eunuch',
    'fecal',
    'felch',
    'female squirting',
    'femdom',
    'figging',
    'fingerbang',
    'fingering',
    'fisting',
    'foot fetish',
    'footjob',
    'frotting',
    'fuck buttons',
    'fucktards',
    'futanari',
    'gang bang',
    'gay sex',
    'genitals',
    'giant cock',
    'girl on',
    'girl on top',
    'girls gone wild',
    'goatcx',
    'god damn',
    'gokkun',
    'golden shower',
    'goodpoop',
    'goo girl',
    'goregasm',
    'grope',
    'group sex',
    'g-spot',
    'guro',
    'hand job',
    'hard core',
    'hardcore',
    'hentai',
    'homoerotic',
    'hooker',
    'hot carl',
    'hot chick',
    'how to kill',
    'how to murder',
    'huge fat',
    'intercourse',
    'jail bait',
    'jailbait',
    'jelly donut',
    'jiggaboo',
    'jiggerboo',
    'juggs',
    'kinbaku',
    'kinkster',
    'kinky',
    'knobbing',
    'leather restraint',
    'leather straight jacket',
    'lemon party',
    'lolita',
    'lovemaking',
    'make me come',
    'male squirting',
    'menage a trois',
    'milf',
    'missionary position',
    'mound of venus',
    'mr hands',
    'muff diver',
    'muffdiving',
    'nambla',
    'nawashi',
    'neonazi',
    'nig nog',
    'nimphomania',
    'nipple',
    'nipples',
    'nsfw images',
    'nude',
    'nudity',
    'nympho',
    'nymphomania',
    'octopussy',
    'omorashi',
    'one cup two girls',
    'one guy one jar',
    'orgy',
    'paedophile',
    'panties',
    'panty',
    'pedobear',
    'pedophile',
    'pegging',
    'phone sex',
    'piece of shit',
    'piss pig',
    'pisspig',
    'playboy',
    'pleasure chest',
    'pole smoker',
    'ponyplay',
    'poof',
    'punany',
    'poop chute',
    'poopchute',
    'prince albert piercing',
    'pthc',
    'pubes',
    'queaf',
    'quim',
    'raghead',
    'raging boner',
    'rape',
    'raping',
    'rapist',
    'reverse cowgirl',
    'rosy palm',
    'rosy palm and her 5 sisters',
    'rusty trombone',
    'sadism',
    'santorum',
    'scat',
    'scissoring',
    'sexo',
    'sexy',
    'shaved beaver',
    'shaved pussy',
    'shibari',
    'shitblimp',
    'shota',
    'shrimping',
    'slanteye',
    's&m',
    'snowballing',
    'sodomize',
    'sodomy',
    'splooge moose',
    'spooge',
    'spread legs',
    'strap on',
    'strapon',
    'strappado',
    'strip club',
    'style doggy',
    'suck',
    'sucks',
    'suicide girls',
    'sultry women',
    'swastika',
    'swinger',
    'tainted love',
    'taste my',
    'tea bagging',
    'threesome',
    'throating',
    'tied up',
    'tight white',
    'titty',
    'tongue in a',
    'topless',
    'towelhead',
    'tranny',
    'tribadism',
    'tub girl',
    'tubgirl',
    'tushy',
    'twink',
    'twinkie',
    'two girls one cup',
    'undressing',
    'upskirt',
    'urethra play',
    'urophilia',
    'venus mound',
    'vibrator',
    'violet wand',
    'vorarephilia',
    'voyeur',
    'wet dream',
    'white power',
    'wrapping men',
    'wrinkled starfish',
    'xx',
    'yaoi',
    'yellow showers',
    'yiffy',
    'zoophilia',
];
